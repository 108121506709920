.contact {
  background-color: #f5f5f5;
  padding: 64px 0 48px;
  border-radius: 8px;
  margin: 20px 0;
  text-align: center;
}

.contact-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.contact-item {
  margin: 20px;
  text-align: center;
  transition: transform 0.3s ease; /* Add a transformation animation on hover */
  cursor: pointer;
}

.contact-item i {
  font-size: 36px;
  margin-bottom: 10px;
}

.contact-item h2 {
  font-size: 18px;
}

.contact-item:hover {
  transform: scale(1.05); /* Scale up the item on hover */
  background-color: #fff; /* Change the background color on hover */
  border-radius: 8px;
}

/* Add styles for Font Awesome icons (you'll need to include Font Awesome in your project) */

.fa-envelope::before { content: "\f0e0"; }
.fa-phone::before { content: "\f095"; }
.fa-twitter::before { content: "\f099"; }
.fa-linkedin::before { content: "\f08c"; }
.fa-github::before { content: "\f09b"; }
