/* Keep the existing styles for .skill-category and .skill-list here */

.product-card {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0;
  margin: 1em auto;
  width: 327px; /* Adjusted width to fit three cards in a row */
  height: auto;
  border: 1px lightgray solid;
  border-radius: 5px;
  transition: box-shadow 0.4s ease-out;
  align-items: center;
}

.product-card:hover {
  box-shadow: var(--shadow);
}

.product-thumbnail {
  overflow: hidden;
  box-sizing: border-box;
  grid-column: 1/-1;
  grid-row: 2/3;
  min-height: 220px;
}

.product-thumbnail img {
  width: 100%;
  min-height: 220px;
  object-fit: contain;
}

/* Style the "View Project" link as a button */
.product-link-button {
  display: block;
  margin-top: auto; /* Align to the bottom */
  background-color: var(--first-color);
  color: #fff;
  text-align: center;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  width: 60%;
  margin-bottom: 5px;
}

.project-link-button:hover {
  background-color: #2c8f63;
}

/* Update the CSS selector for the languages list */
.languages-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
}

.languages-list li {
  background-color: #fff;
  color: var(--first-color);
  font-size: 14px;
  font-weight: 600;
  padding: 1em 0.5em;
  margin: 1em 0.5em;
  border-radius: 5px;
  transition: background-color 0.3s ease; /* Animation for background color */
  cursor: pointer;
}

.languages-list li:hover {
  background-color: #36b37f; /* Background color change on hover */
  color: #fff; /* Text color change on hover */
}
