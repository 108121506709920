/* Keep the existing styles for .skill-category and .skill-list here */

.works {
  display: grid;
  grid-template-columns: 1fr;
  width: 90%;
  text-align: center;
  margin: 0 auto;
  grid-template-rows: 200px repeat(1, 6fr);
}

.works h1:first-child {
  padding: 1em;
}

.works h2:first-child::after {
  content: '';
  height: 4px;
  width: 48px;
  margin: 0 auto;
  display: block;
  position: relative;
  top: 20px;
  z-index: -1;
  border-radius: 8px;
}

.work-card {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0;
  margin: 1em auto;
  width: 400px; /* Adjusted width to fit three cards in a row */
  height: 445px;
  border: 1px lightgray solid;
  border-radius: 5px;
  transition: box-shadow 0.4s ease-out;
  align-items: center;
}

.work-card:hover {
  box-shadow: var(--shadow);
}

.work-thumbnail {
  overflow: hidden;
  box-sizing: border-box;
  grid-column: 1/-1;
  grid-row: 2/3;
  min-height: 220px;
}

.work-thumbnail img {
  width: 100%;
  min-height: 220px;
  object-fit: contain;
}

.project-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: inherit;
}

/* Style the "View Project" link as a button */
.project-link-button {
  display: block;
  background-color: var(--first-color);
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  width: 40%;
  margin: 5px 10px 10px 10px;
}

.project-link-button:hover {
  background-color: #2c8f63;
}

/* Update the CSS selector for the languages list */
.languages-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
}

.languages-list li {
  background-color: #fff;
  color: var(--first-color);
  font-size: 14px;
  font-weight: 600;
  padding: 0.1em 0.1em;
  margin: 0.1em 0.5em;
  border-radius: 3px;
  transition: background-color 0.3s ease; /* Animation for background color */
  cursor: pointer;
}

.languages-list li:hover {
  background-color: #36b37f; /* Background color change on hover */
  color: #fff; /* Text color change on hover */
}
