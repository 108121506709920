.works {
  padding: 64px 0 48px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .works {
    flex-direction: column;
    align-items: center;
  }
}

/* Adjustments for even smaller screens */
@media (max-width: 576px) {
  .works {
    padding: 10px;
  }
}

.work__container {
  display: grid;
  grid-template-columns: repeat(2, minmax(400px, 1fr));
  gap: 2rem;
}

.work__img {
  box-shadow: 0 4px 25px rgba(14, 36, 49, 0.15);
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 1s;
}

.work__img img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.work__img:hover {
  transform: scale(1.1);
}
