:root {
  --hue-color: 224;
  --first-color: hsl(var(--hue-color), 89%, 60%);
  --second-color: hsl(var(--hue-color), 56%, 12%);
}

.skills {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.category {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.category:hover {
  transform: scale(1.05);
}

.category h3 {
  font-size: 18px;
  color: var(--first-color);
}

.skills-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skills-list li {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  border-radius: 8px;
  color: #fff;
  background-color: var(--first-color);
  transition: background-color 0.3s ease-in-out;
}

.skills-list li:hover {
  background-color: var(--second-color);
}
